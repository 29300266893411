import React from "react";

function SectionCard({ Icon, heading, content }) {
  return (
    <div className="sectioncard-container">
      <div className="sectioncard-heading">
        <div className="sectioncard-iconContainer">{Icon}</div>
        <div>{heading}</div>
      </div>
      <div className="sectioncard-content">{content}</div>
    </div>
  );
}

export default SectionCard;

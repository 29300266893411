import { Icon } from "@iconify/react";
import { Col, Navbar, Row } from "react-bootstrap";
import "./App.css";

import SectionCard from "./Cards/SectionCard";
import "./Cards/SectionCard.css";

import ReviewCard from "./Cards/ReviewCard";
import "./Cards/ReviewCard.css";

import LocationCard from "./Cards/LocationCard";
import "./Cards/LocationCard.css";

import Footer from "./Footer";
import "./Footer.css";
import { useEffect } from "react";

const reviews = [
  {
    review:
      "Excellent customer service and highly knowledgeable on business and personal tax frontier. 10/10 would recommend this place to anyone who is looking to get their business or oersonat taxes taken care of. ",
    author: "- Hardy P.",
  },
  {
    review:
      "Very professional and quick service. Wilt be going to Sunny every year now. ",
    author: "- Roger M. ",
  },
  {
    review:
      "Excellent customer service and highly knowledgeable on business and personal tax frontier. 10/10 would recommend this place to anyone who is looking to get their business or oersonat taxes taken care of. ",
    author: "- Amandeep K. ",
  },
];

const locations = [
  {
    address: "1694 Albion Road, Toronto ON, M9V 3K8 ",
    phone: "(647)-712-5455",
  },
  {
    address: "6.A Lavendale Road, Unit 202, Richmond Hilt, L4C 4H2 ",
    phone: "(647)-712-5455",
  },
];

const services = [
  {
    icon: "user",
    heading: "Personal Income Tax",
    content:
      "Helping you with all your personal tac obligations, deductions, capital gains tax and associated reliefs",
  },
  {
    icon: "data-center",
    heading: "Small Business Tax ",
    content:
      "Small Business Tax returns can be overwhelming. We take ca re of filing your small business tax with accuracy & efficiency ",
  },
  {
    icon: "building",
    heading: "CorporateTax ",
    content:
      "We help you file your corporate business tax returns for active as well as inactive corporations. We also advise on tax planning for your business. ",
  },
  {
    icon: "license-draft",
    heading: "GST/HST Business Registration &  Incorporation",
    content:
      "We provide same day business registration services and take care of filing the articles of incorporation, bookkeeping, preparing financial statements and filing income tax returns for your small business. ",
  },
  {
    icon: "load-balancer-application",
    heading: "New & existing Business Consultation   ",
    content:
      "We provide operational and functional advisory services to help you take all the right steps for your new and existing business",
  },
  {
    icon: "home",
    heading: "Housing Rebate ",
    content:
      "We file your housing rebate refund to help you recover a portion of the amount spent while purchasing your property ",
  },
];

const phoneNumber = "12345678";

function App() {
  const bannerLeftMargin = () => {
    const banner = document.querySelector(".mainSection-bannerContainer");

    // adding a dummy element to dom to get the true width of viewport
    var objNode = document.createElement("div");
    objNode.style.width = "100vw";
    document.body.appendChild(objNode);
    var intViewportWidth = objNode.offsetWidth;
    document.body.removeChild(objNode);

    let screenWidth = intViewportWidth;
    let containerPadding = 0;

    if (screenWidth >= 1400) {
      containerPadding = screenWidth - 1320;
    } else if (screenWidth >= 1200) {
      containerPadding = screenWidth - 1140;
    } else if (screenWidth >= 992) {
      containerPadding = screenWidth - 960;
    } else if (screenWidth >= 768) {
      containerPadding = screenWidth - 720;
    } else if (screenWidth >= 576) {
      containerPadding = screenWidth - 540;
    }
    // console.log(containerPadding, screenWidth);
    containerPadding /= 2;
    if (containerPadding > 137) {
      containerPadding = 0;
    }
    banner.style.left = `-${containerPadding}px`;
  };
  const navSlide = () => {
    const burger = document.querySelector(".burger");
    const navLink = document.querySelector(".nav-links");
    const nav = document.querySelector(".nav-links");

    burger.addEventListener("click", () => {
      if (nav.classList.contains("nav-active")) {
        nav.classList.remove("nav-active");
        burger.classList.remove("toggle");
      } else {
        nav.classList.add("nav-active");
        burger.classList.add("toggle");
      }
    });

    navLink.addEventListener("click", () => {
      nav.classList.remove("nav-active");
      burger.classList.remove("toggle");
    });

    //let leftOffset = window.screen.width + parseInt(container.style.width);
    bannerLeftMargin();
    window.addEventListener("resize", () => {
      bannerLeftMargin();
    });
  };

  useEffect(() => {
    navSlide();
  }, []);

  return (
    <>
      <div className="nav-back"></div>
      <div className="container">
        <nav>
          <div className="logo">
            <img src="/righttaxLogo.png"></img>
            <img src="/RIGHTTAX.png" style={{ marginLeft: "10px" }} />
          </div>
          <ul className="nav-links">
            <li>
              <a href="#services" className="nav-link">
                Services
              </a>
            </li>
            <li>
              <a href="#reviews" className="nav-link">
                Customer Reviews
              </a>
            </li>
            <li>
              <a href="#locations" className="nav-link">
                Locations
              </a>
            </li>
          </ul>

          <div className="burger">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </nav>

        <Row className="mainSection">
          <Col md={5}>
            <div className="mainSection-heading">
              <i>
                <strong>Your peace of mind! </strong>
              </i>
              <img src="/smily.png" />
            </div>
            <div className="mainSection-subheading">
              Serving Canadians since 2010!
            </div>
            <button
              className="mainSection-button"
              onClick={() => {
                document.location.href = "#locations";
              }}
            >
              Call now to maximize your tax refund!
            </button>
            <div className="mainSection-bannerContainer">
              <div className="mainSection-banner">
                <div className="mainSection-bannerItem">
                  <div className="mainSection-bannerHeading">
                    Toronto Location
                  </div>
                  <div className="mainSection-bannerPhone">
                    <Icon icon="carbon:phone-filled" /> (647)-712-5455
                  </div>
                </div>
                <div>
                  <div className="mainSection-bannerHeading">
                    Richmond Hill Location
                  </div>
                  <div className="mainSection-bannerPhone">
                    <Icon icon="carbon:phone-filled" /> (416)-317-7575
                  </div>
                </div>
              </div>
              <div className="mainSection-bannerCut"></div>
            </div>
          </Col>
          <Col md={7} lg={5}>
            <div className="mainSection-imageContainer">
              <img src="/image 2.png" alt="" className="mainSection-image" />
            </div>
          </Col>
        </Row>

        {/* Service */}

        <Row
          className="servicesSection"
          id="services"
          style={{ position: "relative", zIndex: 1 }}
        >
          <div className="servicedots">
            <img src="/dots.png" />
          </div>
          <div className="servicesSection-heading">Services</div>
          <Row className="mt-1 align-items-stretch">
            {services.map((service) => (
              <Col md={6} lg={4} className="mt-5">
                <SectionCard
                  Icon={<Icon icon={`carbon:${service.icon}`} />}
                  heading={service.heading}
                  content={service.content}
                />
              </Col>
            ))}
          </Row>
        </Row>

        {/* Reviews */}
        <Row className="reviewSection" id="reviews">
          <div className="reviewdots">
            <img src="/dots.png" />
          </div>
          <div className="reviewSection-heading">Reviews</div>
          <Row className="mt-5 align-items-stretch">
            {reviews.map((review) => (
              <Col md={6} lg={4} className="mt-5">
                <ReviewCard review={review.review} author={review.author} />
              </Col>
            ))}
          </Row>
        </Row>

        {/* Location */}
        <Row className="locationSection" id="locations">
          <div className="locationSection-heading">Locations</div>
          <Row className="mt-5 align-items-stretch">
            {locations.map((location) => (
              <Col md={6} lg={6} className="mt-5">
                <LocationCard
                  address={location.address}
                  phone={location.phone}
                />
              </Col>
            ))}
          </Row>
        </Row>
      </div>
      <Footer />
    </>
  );
}

export default App;

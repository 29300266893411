import React from "react";
import { Icon } from "@iconify/react";
import { Col, Row } from "react-bootstrap";
function Footer() {
  return (
    <div className="footer-container">
      <Row className="justify-content-between">
        <Col sm={4} className="d-flex gap-3">
          <span>Privacy Policies</span>

          <span>Terms and conditions</span>
        </Col>
        <Col sm={4} style={{ textAlign: "center" }}>
          &copy; RightTax 2022
        </Col>
        <Col sm={4} className="d-flex justify-content-end gap-1">
          <Icon icon="carbon:logo-facebook" />
          <Icon icon="carbon:logo-instagram" />
          <Icon icon="carbon:logo-twitter" />
          <Icon icon="carbon:logo-linkedin" />
        </Col>
      </Row>
    </div>
  );
}

export default Footer;

import React from "react";
import { Col, Row } from "react-bootstrap";

function ReviewCard({ review, author }) {
  return (
    <div className="reviewcard-container">
      <Row>
        <Col xs={2}>
          <img src="/quotes.png" />
        </Col>
        <Col xs={10} className="reviewcard-contentContainer">
          <div className="reviewcard-review">{review}</div>
          <div className="reviewcard-author">
            <strong>{author}</strong>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ReviewCard;

import { Icon } from "@iconify/react";
import React from "react";

function LocationCard({ address, phone }) {
  return (
    <div className="locationcard-container">
      <div className="locationcard-address">
        <strong>{address}</strong>
      </div>
      <div className="locationcard-phone">
        <Icon icon="carbon:phone-filled" /> {phone}
      </div>
    </div>
  );
}

export default LocationCard;
